import { countryLookup, territoryOptions } from '../common/helpers/Address';

export const EVENTS = {
    ADD_FIELD: 'AddField',
    COPY_FORM: 'CopyForm',
    CREATE_FORM: 'CreateForm',
    DELETE_FORM: 'DeleteForm',
    GET_CODE_DSAR: 'GetCodeDSAR',
    OPEN_DETAILS: 'OpenDetails',
    OPEN_FORM_FIELDS: 'OpenFormFields',
    OPEN_REQUEST_TYPES: 'OpenRequestTypes',
    OPEN_STYLING: 'OpenStyling',
    SAVE_FORM: 'SaveForm',
    SELECT_FIELD_TYPE: 'SelectFieldType',
};

export const DSAR_REQUEST_TYPES = {
    DELETION: 'DELETE',
    CORRECTION: 'CORRECT',
    CUSTOM_1: 'CUSTOM_1',
    CUSTOM_2: 'CUSTOM_2',
    CUSTOM_3: 'CUSTOM_3',
    CUSTOM_4: 'CUSTOM_4',
    CUSTOM_5: 'CUSTOM_5',
    DO_NOT_SELL: 'DO_NOT_SELL',
    LIMIT_USE: 'LIMIT_USE',
    OPT_OUT_OF_USING_SENSITIVE_DATA: 'OPT_OUT_OF_USING_SENSITIVE_DATA',
    OPT_OUT: 'OPT_OUT',
    OTHER: 'OTHER',
    PORTABILITY: 'PORTABILITY',
    SUMMARIZE: 'SUMMARIZE',
};

export const DSAR_REQUEST_TYPE_LABELS = {
    [DSAR_REQUEST_TYPES.DELETION]: 'dsar.requestType.deletion',
    [DSAR_REQUEST_TYPES.CORRECTION]: 'dsar.requestType.correction',
    [DSAR_REQUEST_TYPES.CUSTOM_1]: 'dsar.requestType.custom1',
    [DSAR_REQUEST_TYPES.CUSTOM_2]: 'dsar.requestType.custom2',
    [DSAR_REQUEST_TYPES.CUSTOM_3]: 'dsar.requestType.custom3',
    [DSAR_REQUEST_TYPES.CUSTOM_4]: 'dsar.requestType.custom4',
    [DSAR_REQUEST_TYPES.CUSTOM_5]: 'dsar.requestType.custom5',
    [DSAR_REQUEST_TYPES.DO_NOT_SELL]: 'dsar.requestType.doNotSell',
    [DSAR_REQUEST_TYPES.LIMIT_USE]: 'dsar.requestType.limitUse',
    [DSAR_REQUEST_TYPES.OPT_OUT_OF_USING_SENSITIVE_DATA]:
        'dsar.requestType.optOutOfUsingSensitiveData',
    [DSAR_REQUEST_TYPES.OPT_OUT]: 'dsar.requestType.optOut',
    [DSAR_REQUEST_TYPES.OTHER]: 'dsar.requestType.other',
    [DSAR_REQUEST_TYPES.PORTABILITY]: 'dsar.requestType.portability',
    [DSAR_REQUEST_TYPES.SUMMARIZE]: 'dsar.requestType.summarize',
};

export const DSAR_REQUEST_TYPE_LABELS_ENGLISH = {
    CORRECTION: 'Correct',
    CUSTOM: 'Custom',
    DELETION: 'Delete',
    DO_NOT_SELL: 'Do Not Sell or Share to a Third Party',
    LIMIT_USE: 'Limit Use of Sensitive Data',
    OPT_OUT_OF_USING_SENSITIVE_DATA: 'Opt Out of Using Sensitive Data',
    OPT_OUT: 'Opt Out of Targeted Advertising',
    OTHER: 'Other',
    PORTABILITY: 'Portability',
    SUMMARIZE: 'Summarize',
};

// TODO: Remove constant once DSAR forms have internationalization
export const DSAR_REQUEST_TYPE_LABELS_TRANSLATIONS = {
    'dsar.requestType.correction': DSAR_REQUEST_TYPE_LABELS_ENGLISH.CORRECTION,
    'dsar.requestType.custom': DSAR_REQUEST_TYPE_LABELS_ENGLISH.CUSTOM,
    'dsar.requestType.deletion': DSAR_REQUEST_TYPE_LABELS_ENGLISH.DELETION,
    'dsar.requestType.doNotSell': DSAR_REQUEST_TYPE_LABELS_ENGLISH.DO_NOT_SELL,
    'dsar.requestType.limitUse': DSAR_REQUEST_TYPE_LABELS_ENGLISH.LIMIT_USE,
    'dsar.requestType.optOutOfUsingSensitiveData':
        DSAR_REQUEST_TYPE_LABELS_ENGLISH.OPT_OUT_OF_USING_SENSITIVE_DATA,
    'dsar.requestType.optOut': DSAR_REQUEST_TYPE_LABELS_ENGLISH.OPT_OUT,
    'dsar.requestType.other': DSAR_REQUEST_TYPE_LABELS_ENGLISH.OTHER,
    'dsar.requestType.portability': DSAR_REQUEST_TYPE_LABELS_ENGLISH.PORTABILITY,
    'dsar.requestType.summarize': DSAR_REQUEST_TYPE_LABELS_ENGLISH.SUMMARIZE,
};

export const DSAR_REQUESTOR_TYPES = {
    EMPLOYEE: 'dsar.requestor.employee',
    CUSTOMER: 'dsar.requestor.subject',
    OTHER: 'dsar.requestor.other',
};

export const DSAR_REQUESTOR_TYPES_ENGLISH = {
    EMPLOYEE: 'Employee',
    CUSTOMER: 'Subject',
    OTHER: 'Other',
};

export const FILE_DSAR_FIELD_TYPE = 'file';
export const READ_ONLY_DSAR_FIELD_TYPE = 'readonly';

export const DSAR_FIELD_TYPES = [
    { value: 'text', label: 'dsarFieldTypes.textField' },
    { value: READ_ONLY_DSAR_FIELD_TYPE, label: 'dsarFieldTypes.readOnly' },
    { value: 'email', label: 'dsarFieldTypes.emailTextField' },
    { value: 'textarea', label: 'dsarFieldTypes.multilineTextField' },
    { value: 'select', label: 'dsarFieldTypes.dropdown' },
    { value: 'multiselect', label: 'dsarFieldTypes.multiSelectDropdown' },
    { value: 'checkbox', label: 'dsarFieldTypes.checkbox' },
    { value: 'datepicker', label: 'dsarFieldTypes.datePicker' },
    { value: 'hidden', label: 'dsarFieldTypes.hiddenField' },
    { value: FILE_DSAR_FIELD_TYPE, label: 'dsarFieldTypes.fileUpload' },
];

export const DSAR_FONTS = [
    { value: 'Arial, Helvetica, sans-serif', label: 'Arial, Helvetica, sans-serif' },
    { value: 'Impact, Charcoal, sans-serif', label: 'Impact, Charcoal, sans-serif' },
    { value: 'Georgia, serif', label: 'Georgia, serif' },
    { value: '"Times New Roman", Times, serif', label: 'Times New Roman, Times, serif' },
    { value: '"Courier New", Courier, monospace', label: 'Courier New, Courier, monospace' },
    { value: '"Lucida Console", Monaco, monospace', label: 'Lucida Console, Monaco, monospace' },
];

export const COUNTRY_ID = 'country';
export const IDENTITY_VERIFY_ID = 'identification-file';
export const REQUEST_DESCRIPTION_ID = 'request-description';
export const TERRITORY_ID = 'territory';
export const REQUEST_TYPE = 'request-type';
export const DSAR_COUNTRY_LOOKUP = Object.values(countryLookup).reduce(
    (countries, { name, twoLetter }) => {
        countries[twoLetter] = {
            value: twoLetter,
            label: name,
        };
        return countries;
    },
    {}
);
export const DSAR_TERRITORY_LOOKUP = Object.entries(territoryOptions).reduce(
    (territories, [country, countryTerritories]) => {
        const { twoLetter } = countryLookup[country.toLowerCase()];
        Object.entries(countryTerritories).forEach(([value, { name }]) => {
            territories[`${value}`] = {
                value,
                label: name,
                group: twoLetter,
                groupName: country,
            };
        });
        return territories;
    },
    {}
);
export const MAX_ID_FILE_SIZE_MB = 8;
export const DISALLOWED_FILE_TYPES = ['image/svg+xml'];

export const DSAR_DETAILS_FIELD_IDS = {
    ATTACHMENTS: 'attachments',
    CITY: 'city',
    COUNTRY: 'country',
    COUNTRY_GEO: 'country-geo',
    COUNTRY_NAME: 'country-name',
    CREATED: 'created',
    CUSTOMER_ID: 'customer-id',
    DUE_DATE: 'due-date',
    EMAIL: 'email',
    FAMILY_NAME: 'family-name',
    FORM_ID: 'form-id',
    GIVEN_NAME: 'given-name',
    IDENTIFICATION_FILE: 'identification-file',
    IMAGE_EXTENSION: 'image-extension',
    INTERNAL_DUE_DATE: 'internal-due-date',
    LANGUAGE: 'language',
    PHONE_NUMBER: 'phone-number',
    PRIVACY_LAW: 'privacy-law',
    PRIVACY_LAW_DEADLINE: 'privacy-law-deadline',
    PRIVACY_LAW_STATUS: 'privacy-law-status',
    REQUESTOR_TYPE: 'requestor-type',
    REQUEST_DESCRIPTION: 'request-description',
    REQUEST_ID: 'request-id',
    REQUEST_SOURCE: 'request-source',
    REQUEST_TYPE: 'request-type',
    REQUEST_TYPE_DESCRIPTION: 'request-type-description',
    REQUEST_TYPE_ID: 'request-type-id',
    REQUEST_TYPE_INSTRUCTIONS: 'request-type-instructions',
    REQUEST_TYPE_TITLE: 'request-type-title',
    TERRITORY: 'territory',
    TERRITORY_GEO: 'territory-geo',
    TERRITORY_NAME: 'territory-name',
    TWILIO: 'twilio',
    UPDATED: 'updated',
};

const DSAR_STANDARD_FIELDS = {
    [DSAR_DETAILS_FIELD_IDS.EMAIL]: {
        i18nLabel: 'dsar.fields.email.label',
        enLabel: 'Email',
        type: 'email',
        required: true,
    },
    [DSAR_DETAILS_FIELD_IDS.GIVEN_NAME]: {
        i18nLabel: 'dsar.fields.firstName.label',
        enLabel: 'First Name',
        type: 'text',
        required: true,
        excludable: true,
        excluded: false,
    },
    [DSAR_DETAILS_FIELD_IDS.FAMILY_NAME]: {
        i18nLabel: 'dsar.fields.lastName.label',
        enLabel: 'Last Name',
        type: 'text',
        required: true,
        excludable: true,
        excluded: false,
    },
    [DSAR_DETAILS_FIELD_IDS.COUNTRY]: {
        i18nLabel: 'dsar.fields.countryOfResidence.label',
        enLabel: 'Country of Residence',
        type: 'select',
        options: Object.values(DSAR_COUNTRY_LOOKUP),
        required: true,
        excludable: true,
        excluded: false,
    },
    [DSAR_DETAILS_FIELD_IDS.TERRITORY]: {
        i18nLabel: 'dsar.fields.stateProvinceTerritory.label',
        enLabel: 'State / Province / Territory of Residence',
        type: 'select',
        options: Object.values(DSAR_TERRITORY_LOOKUP),
        required: true,
        excludable: true,
        excluded: false,
    },
    [DSAR_DETAILS_FIELD_IDS.IDENTIFICATION_FILE]: {
        i18nLabel: 'dsar.fields.proofOfIdentity.label',
        enLabel: 'Proof of Identity',
        type: 'file',
        required: true,
        placeholder: 'Select Identification',
        helperText:
            'Attention! To prove your identity you must provide a photo of yourself holding your identity document next to your face, where both are visible in the photo.\nMust be under ' +
            MAX_ID_FILE_SIZE_MB +
            ' MB.',
        excludable: true,
        excluded: false,
    },
    [DSAR_DETAILS_FIELD_IDS.REQUESTOR_TYPE]: {
        i18nLabel: 'dsar.fields.requestorType.label',
        enLabel: 'Requestor Type',
        type: 'select',
        options: Object.entries(DSAR_REQUESTOR_TYPES_ENGLISH)
            .sort(([, labelA], [, labelB]) => labelA.localeCompare(labelB))
            .map(([value, label]) => ({ value, label })),
        required: true,
        editable: true,
        excludable: true,
        excluded: false,
    },
    [DSAR_DETAILS_FIELD_IDS.REQUEST_TYPE]: {
        i18nLabel: 'dsar.fields.requestType.label',
        enLabel: 'Request Type',
        type: 'select',
        options: Object.entries(DSAR_REQUEST_TYPE_LABELS_ENGLISH)
            .sort(([, labelA], [, labelB]) => labelA.localeCompare(labelB))
            .map(([value, label]) => ({ value, label })),
        required: true,
        editable: true,
        excludable: false,
        excluded: false,
        canShowHide: false,
    },
    [DSAR_DETAILS_FIELD_IDS.REQUEST_DESCRIPTION]: {
        i18nLabel: 'dsar.fields.requestDescription.label',
        enLabel: 'Request Description',
        type: 'textarea',
        required: false,
        excludable: true,
        excluded: true,
    },
};

export const DSAR_STANDARD_FIELDS_EN = Object.keys(DSAR_STANDARD_FIELDS).reduce((acc, curr) => {
    // eslint-disable-next-line no-unused-vars
    const { i18nLabel, enLabel, ...rest } = DSAR_STANDARD_FIELDS[curr];
    acc[curr] = { ...rest, label: enLabel };
    return acc;
}, {});

export const DSAR_STANDARD_FIELDS_I18N = Object.keys(DSAR_STANDARD_FIELDS).reduce((acc, curr) => {
    // eslint-disable-next-line no-unused-vars
    const { i18nLabel, enLabel, ...rest } = DSAR_STANDARD_FIELDS[curr];
    acc[curr] = { ...rest, label: i18nLabel };
    return acc;
}, {});

export const DSAR_INFO_FIELDS = {
    [DSAR_DETAILS_FIELD_IDS.ATTACHMENTS]: {
        label: 'common.attachments',
    },
    [DSAR_DETAILS_FIELD_IDS.COUNTRY]: {
        label: 'dsar.fields.userSelectedLocation.label',
        helperText: 'dsar.fields.userSelectedLocation.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.COUNTRY_GEO]: {
        label: 'dsar.fields.systemIdentifiedLocation.label',
        helperText: 'dsar.fields.systemIdentifiedLocation.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.CREATED]: {
        label: 'common.created',
    },
    [DSAR_DETAILS_FIELD_IDS.DUE_DATE]: {
        label: 'dsar.fields.regulatoryDueDate.label',
        helperText: 'dsar.fields.regulatoryDueDate.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.EMAIL]: {
        label: 'dsar.fields.email.label',
        helperText: 'dsar.fields.email.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.FAMILY_NAME]: {
        label: 'dsar.fields.lastName.label',
        helperText: 'dsar.fields.lastName.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.FORM_ID]: {
        label: 'dsar.fields.form.label',
        helperText: 'dsar.fields.form.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.GIVEN_NAME]: {
        label: 'dsar.fields.firstName.label',
        helperText: 'dsar.fields.firstName.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.INTERNAL_DUE_DATE]: {
        label: 'dsar.fields.dueDate.label',
        helperText: 'dsar.fields.dueDate.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.LANGUAGE]: {
        label: 'dsar.fields.language.label',
        helperText: 'dsar.fields.language.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.PHONE_NUMBER]: {
        label: 'dsar.fields.phoneNumber.label',
        helperText: 'dsar.fields.phoneNumber.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.PRIVACY_LAW]: {
        label: 'dsar.fields.privacyLaw.label',
        helperText: 'dsar.fields.privacyLaw.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.PRIVACY_LAW_DEADLINE]: {
        label: 'dsar.fields.privacyLawDeadline.label',
        helperText: 'dsar.fields.privacyLawDeadline.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.REQUESTOR_TYPE]: {
        label: 'dsar.fields.requestorType.label',
        helperText: 'dsar.fields.requestorType.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.REQUEST_DESCRIPTION]: {
        label: 'dsar.fields.requestDescription.label',
        helperText: 'dsar.fields.requestDescription.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.REQUEST_ID]: {
        label: 'dsar.fields.requestId.label',
        helperText: 'dsar.fields.requestId.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.REQUEST_SOURCE]: {
        label: 'dsar.fields.requestSource.label',
        helperText: 'dsar.fields.requestSource.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.REQUEST_TYPE]: {
        label: 'dsar.fields.requestType.label',
        helperText: 'dsar.fields.requestType.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.REQUEST_TYPE_DESCRIPTION]: {
        label: 'dsar.fields.requestTypeDescription.label',
        helperText: 'dsar.fields.requestTypeDescription.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.REQUEST_TYPE_INSTRUCTIONS]: {
        label: 'dsar.fields.requestTypeInstructions.label',
        helperText: 'dsar.fields.requestTypeInstructions.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.REQUEST_TYPE_TITLE]: {
        label: 'dsar.fields.requestTypeTitle.label',
        helperText: 'dsar.fields.requestTypeTitle.helperText',
    },
    [DSAR_DETAILS_FIELD_IDS.UPDATED]: {
        label: 'common.updated',
    },
};

export const DSAR_TEMPLATE_TYPE_EMAIL = 'EMAIL';
export const DSAR_TEMPLATE_TYPE_WEB = 'WEB';
export const DSAR_TEMPLATE_TYPE_PORTAL = 'PORTAL';

export const DSAR_TEMPLATE_SUBTYPE_COMPLETION = 'COMPLETION';

export const DSAR_TEMPLATE_SUBTYPE_REJECTION = 'REJECTION';

export const DSAR_TEMPLATE_SUBTYPE_PORTAL = 'PORTAL_MESSAGE';

export const DSAR_TEMPLATE_TYPES = {
    [DSAR_TEMPLATE_TYPE_EMAIL]: 'dsar.templates.types.email', // "Email"
    [DSAR_TEMPLATE_TYPE_WEB]: 'dsar.templates.types.web', // "Web"
    [DSAR_TEMPLATE_TYPE_PORTAL]: 'dsar.templates.types.portal', // "Portal"
};

export const DSAR_RICH_TEXT_TOOLBAR_OPTIONS = [
    ['heading', 'bold', 'italic', 'strike', 'quote', 'hr'],
    ['ul', 'ol', 'indent', 'outdent'],
    ['table', 'link'],
];

export const DSAR_REQUEST_TYPE_JURISDICTIONS = {
    DEFAULT: 'default',
    ENABLED: 'enabled',
    DISABLED: 'disabled',
};

export const DSAR_PRIVACY_LAW_STATUSES = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
};
